var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('HeaderTab',{attrs:{"title":_vm.getTrad('monte.contract_list'),"back_action":_vm.back_action}}),_c('div',{attrs:{"id":"content"}},[(_vm.table)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"box"},[_c('SeasonInput',{on:{"change":_vm.changeSeason},model:{value:(_vm.seasonSelected),callback:function ($$v) {_vm.seasonSelected=$$v},expression:"seasonSelected"}}),(_vm.seasonSelected != null && _vm.contract_ids != null)?[_c('CustomTable',{ref:"contractTable",attrs:{"id_table":"contract","busy":_vm.table_busy,"externSlotColumns":_vm.extern_slot_columns,"primaryKey":"contract_id","hide_if_empty":true,"rawColumns":_vm.rawColumnNames,"hrefsRoutes":_vm.config_table_hrefs,"transformer":['ContractTransformer', 'withAllAvenantArticles'],"base-filters":_vm.filters},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(avenant.avenant_free)",fn:function(ref){
var data = ref.data;
return [(data.avenant && data.avenant.avenant_free)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(avenant.type_monte.contract_type_monte.contracttypemonte_label)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$t(data.avenant.type_monte.contract_type_monte.contracttypemonte_label))+" ")]}},{key:"custom-slot-cell(avenant.avenant_dn_bool)",fn:function(ref){
var data = ref.data;
return [(data.avenant && data.avenant.avenant_dn_bool)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(avenant.avenant_as_bool)",fn:function(ref){
var data = ref.data;
return [(data.avenant && data.avenant.avenant_as_bool)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(avenant.avenant_revue_bool)",fn:function(ref){
var data = ref.data;
return [(data.avenant && data.avenant.avenant_revue_bool)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(contract_sent)",fn:function(ref){
var data = ref.data;
return [(data.contract_sent)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fal', 'check-circle']}}):_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fal', 'times-circle']}})]}},{key:"custom-slot-cell(formatted_invoices)",fn:function(ref){
var data = ref.data;
return _vm._l((data.formatted_invoices),function(invoice){return _c('p',{key:invoice.invoice_id},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.quickPreview(invoice.invoice_id)}}},[_vm._v(_vm._s(invoice.invoice_num))]),_vm._v(" ("+_vm._s(_vm.$t('invoice.invoice_balance'))+" : "+_vm._s(invoice.invoice_balance)+") ")])})}}],null,true)})]:_vm._e()],2)])])]):_c('div',[_c('ContractShutter',{attrs:{"contract_id":_vm.contract_id,"version":_vm.version}})],1)]),_c('b-modal',{ref:"modelPreview",attrs:{"size":"xl","hide-footer":""},on:{"hidden":function($event){_vm.base64 = null}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("action.previsualiser"))+" ")]},proxy:true}])},[(_vm.base64 != null)?_c('iframe',{staticStyle:{"position":"relative"},attrs:{"src":'data:application/pdf;base64,' + _vm.base64,"height":"1000px","width":"100%"}}):_c('div',[_c('LoadingSpinner')],1)]),_c('b-modal',{ref:"modalEnvoie",attrs:{"hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("courrier_type.ajout"))+" ")]},proxy:true}])},[_c('e-select',{staticClass:"primary",attrs:{"id":"models","track-by":"model_label","label":"model_label","options":_vm.models,"searchable":true,"show-labels":false,"allow-empty":false},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.model_label))]}}]),model:{value:(_vm.model_selected),callback:function ($$v) {_vm.model_selected=$$v},expression:"model_selected"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2),(_vm.model_selected)?_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"mt-3",attrs:{"pill":"","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.addModelCourrier($event)}}},[(_vm.processing)?_c('font-awesome-icon',{attrs:{"icon":['fas', 'spinner'],"pulse":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("courrier_type.choisir")))],1)],1):_vm._e()],1),_c('ModalContractModifRapide',{ref:"modal_contract_modif_rapide"}),_c('ModalSendContract',{ref:"modal_send_contract",on:{"submit":_vm.refreshTable}}),_c('ModalEditQualification',{ref:"modal_edit_qualification"}),_c('ModalActDGLot',{ref:"modal_act_dg_lot",on:{"submit":_vm.refreshTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }